
.navbar{
    background-color: #000023;
    height: 100px;
    width: 100%;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    border-bottom: 2px solid white;

}
.menu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.img-logo-navbar{
    width:200px;
    height:80px;
    object-fit: contain;
}
.onglet{
    /*background-color: red;*/
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    padding: 10px 20px;
}
.icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.button{
    background-color: #004EFF;
    color: white;
    border: none;
    border-radius: 5px;
    height: 50px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
a{
    text-decoration: none;
    color: white;
}
