body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* ============================================================================================= */
/* HOME */
/* ============================================================================================= */

#bg-home {
    background-image:  url('../../public/assets/images/bg-home.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    text-align: center;
}
#container-white {
    background-color: #fff;
    width: 40%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    padding: 7%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

/* Form */
.subtitle{
    font-size: 1.5rem;
    font-weight: bold;
    color: #004EFF;
    margin-bottom: 20px;
}
.img-logo-home {
    margin-bottom: 40px;
}
.row{
    width: 100%;
}
.container-input {
    display: flex;
    margin-bottom: 20px;
    background-color: rgba(217, 217, 217, 0.19);
    height: 50px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-radius: 7px;
}
input {
    border: none;
    background-color: transparent;
    height: 50px;
    width: 100%;
    font-size: 14px;
    color:#707070;
}

input[type="submit"] {
    background-color: #004EFF;
    color: #fff;
    font-weight: bold;
    border: none;
    height: 50px;
    width: 100%;
    border-radius: 7px;
    font-size: 14px;
    cursor: pointer;
}
.text{
    font-size: 14px;
    color: #707070;
    margin-top: 20px;
}
.link-blue{
    color: #004EFF;
    text-decoration: underline;
}
.link-underline{
    color: #707070;
    text-decoration: underline;
}
#mdp {
    justify-content: center;
    align-items: flex-start;
    margin-top: -40px;
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    #container-white {
        width: 90%;
    }
}
